@mixin button-links($color: color($grey, 1000)) {
    a,
    a:hover,
    button,
    button:hover {
        color: $color;
        text-decoration: none;

        svg {
            fill: $color;
        }
    }

    a {
        display: block;
    }
}

.header-package.business-entry {
    &.avatar-menu,
    &.about-menu,
    &.solutions-menu {
        @include button-links;

        &.pro {
            @include button-links(color($grey, 1100));
        }
    }
}
