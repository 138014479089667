.fiverr-header {
    .site-logo {
        width: 89px;
        height: 27px;
        display: block;
        margin: -5px auto;
        align-self: flex-start;

        @include media-query-grid(sm) {
            margin: -4px 32px 0 0;
            align-self: auto;
        }
    }
}
